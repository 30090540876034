export const environment = {
    production: true,
    single: 'https://single-variant.prod2.gva.semgenome.com/',
    server: 'UI_SERVER_PROD_AZURE',
    AWSurl: 'https://usermanagement.prod2.gva.semgenome.com',
    report_url: 'https://reports.prod2.gva.semgenome.com',
    usersFileContainerName: 'user-file-system',
    azureStorageUrl: 'https://prodgvauserfiles7l3n.blob.core.windows.net',
    user : 'https://usermanagement.prod2.gva.semgenome.com/',
    status_case_init: 'https://case-init.prod2.gva.semgenome.com/',  // Case INIT 
    file: 'https://upload.prod2.gva.semgenome.com/file/',
    hpo : 'https://search.prod2.gva.semgenome.com/',
    rules : 'https://rules.prod2.gva.semgenome.com/',
    info: 'https://input-info.prod2.gva.semgenome.com/',
    advanced_settings : 'https://advanced-settings.prod2.gva.semgenome.com/',  
    status_usergva: 'https://usergva.prod2.gva.semgenome.com/',
    filters: 'https://filters.prod2.gva.semgenome.com/',
    filter_panel: 'https://filter-panel.prod2.gva.semgenome.com/',
    cnv_acmg  : 'https://cnv-acmg.prod2.gva.semgenome.com/',
    cnv: 'https://cnv.prod2.gva.semgenome.com/',
    acmg : 'https://snv-acmg.prod2.gva.semgenome.com/',
    status_confirm : 'https://confirm.prod2.gva.semgenome.com/',
    viewset : 'https://kg-search.prod2.gva.semgenome.com/',  // KG Search URL
    pubmed : 'https://pubmed-integration.prod2.gva.semgenome.com/',
    vcf_settings : 'https://vcf-settings.prod2.gva.semgenome.com/',
    report : 'https://reports.prod2.gva.semgenome.com/',
    modify_usergva : 'https://case-init.prod2.gva.semgenome.com/',
    upload_file: 'https://notification.prod2.gva.semgenome.com/',  // Notification
    report_image: 'https://reports.prod2.gva.semgenome.com/fetch', // Haeder and Footer
    key: 'IW84Zmx2a21wKW1hdWw2MzlrMSMxcGZk',
    env: 'aws',
    console: 'https://consoleapi.prod2.gva.semgenome.com/',
    oneDrive: 'c9a4bb1d-2e0c-42f1-b08c-195fdc17881c',
    dropBox: '3d4bjxvkav93upc'
  };
  